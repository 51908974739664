import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, mergeProps as _mergeProps, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-74674e64"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "scrollRef",
  class: "kanban-main__content"
}
const _hoisted_2 = ["onDragstart"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_kanban_new_column = _resolveComponent("kanban-new-column")!
  const _component_tm_scrollbar_x_shadows = _resolveComponent("tm-scrollbar-x-shadows")!

  return (_openBlock(), _createElementBlock("div", {
    class: "kanban-main",
    onDragover: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.dragOver && _ctx.dragOver(...args)))
  }, [
    _createVNode(_component_tm_scrollbar_x_shadows, {
      "allow-scroll-y": false,
      class: "h100pr"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columnsComputed, (item) => {
            return (_openBlock(), _createElementBlock("div", _mergeProps({
              key: item.id,
              draggable: true,
              class: _ctx.kanbanColumnDraggableClass
            }, item, {
              onDragstart: ($event: any) => (_ctx.dragStart($event, item.id)),
              onDragend: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.dragEnd && _ctx.dragEnd(...args)))
            }), [
              _renderSlot(_ctx.$slots, "column", { column: item }, undefined, true)
            ], 16, _hoisted_2))
          }), 128)),
          (!_ctx.hideNewColumn)
            ? (_openBlock(), _createBlock(_component_kanban_new_column, { key: 0 }))
            : _createCommentVNode("", true)
        ], 512)
      ]),
      _: 3
    })
  ], 32))
}