import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_deals_filter = _resolveComponent("deals-filter")!
  const _component_deals_main = _resolveComponent("deals-main")!
  const _component_page_content = _resolveComponent("page-content")!

  return (_openBlock(), _createBlock(_component_page_content, {
    title: "Sales US",
    "no-scroll": ""
  }, {
    "filter-bar-dropdown": _withCtx(() => [
      _renderSlot(_ctx.$slots, "filter-bar-dropdown")
    ]),
    "filter-bar-slot": _withCtx(() => [
      _renderSlot(_ctx.$slots, "filter-bar-slot")
    ]),
    "page-content-top-right": _withCtx(() => [
      _renderSlot(_ctx.$slots, "page-content-top-right")
    ]),
    default: _withCtx(() => [
      _createVNode(_component_deals_filter, {
        class: "mb-4",
        "sorting-options": _ctx.sortingOptions
      }, null, 8, ["sorting-options"]),
      _createVNode(_component_deals_main)
    ]),
    _: 3
  }))
}