
import { defineComponent } from 'vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import DealsFilter from '@/components/pages/deals/DealsFilter.vue'
import DealsMain from '@/components/pages/deals/DealsMain.vue'
import { sortingOptions } from '@/definitions/deals/data'

export default defineComponent({
  components: {
    DealsMain,
    DealsFilter,
    PageContent,
  },
  setup() {
    return {
      sortingOptions,
    }
  },
})
