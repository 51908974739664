import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_scrollbar = _resolveComponent("tm-scrollbar")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["tm-scrollbar-x-shadows", {
      'tm-scrollbar-x-shadows--reach-in': _ctx.reachIn,
      'tm-scrollbar-x-shadows--reach-end': _ctx.reachEnd,
      'tm-scrollbar-x-shadows--no-scroll': !_ctx.isScrollable,
    }])
  }, [
    _createVNode(_component_tm_scrollbar, {
      ref: "scrollbar",
      "allow-scroll-y": _ctx.allowScrollY,
      "allow-scroll-x": "",
      onOnXReachIn: _ctx.handleReachIn,
      onOnXReachEnd: _ctx.handleReachEnd,
      onOnXReachStart: _ctx.handleReachStart
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]),
      _: 3
    }, 8, ["allow-scroll-y", "onOnXReachIn", "onOnXReachEnd", "onOnXReachStart"])
  ], 2))
}