
import { defineComponent, ref } from 'vue'
import KanbanCard from '@/components/pages/shared/kanban/KanbanCard.vue'
import TmAvatar from '@/components/shared/TmAvatar.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'

import { assigneeOptions } from '@/definitions/_general/_data/optionsList'
import type { PersonType } from '@/definitions/shared/types'
import type { PropType } from 'vue'
import { useDeals } from '@/compositions/deals/useDeals'
import { useModals } from '@/compositions/modals'

export default defineComponent({
  components: {
    KanbanCard,
    TmAvatar,
    TmDropdown,
    TmDropdownItem,
  },
  props: {
    organization: {
      type: String,
    },
    value: {
      type: Number,
    },
    contactName: {
      type: Object as PropType<PersonType>,
    },
    dealOwner: {
      type: Object as PropType<PersonType>,
    },
  },
  setup() {
    const { getFormattedValue } = useDeals()
    const dropdownVisible = ref(false)
    const { openModal } = useModals()

    return {
      openModal,
      dropdownVisible,
      getFormattedValue,
      assigneeOptions,
    }
  },
})
