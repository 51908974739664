
import { defineComponent, ref } from 'vue'
import TmColorPalette from '@/components/shared/TmColorPalette.vue'
import { colorPaletteOptionsOutline } from '@/definitions/_general/_data/colorPaletteOptions'
import { styleVars, rgbToHex } from '@/compositions/styleVariables'
import TmButton from '@/components/shared/TmButton.vue'
import KanbanColumn from '@/components/pages/shared/kanban/KanbanColumn.vue'

export default defineComponent({
  components: {
    KanbanColumn,
    TmColorPalette,
    TmButton,
  },
  setup() {
    const showCreating = ref(false)
    const color = ref(colorPaletteOptionsOutline[0])
    const name = ref('')

    return {
      color,
      name,
      styleVars,
      rgbToHex,
      showCreating,
      colorPaletteOptionsOutline,
    }
  },
})
