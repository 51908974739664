
import { defineComponent, onBeforeUnmount, onMounted, ref } from 'vue'
import TmScrollbar from '@/components/shared/TmScrollbar.vue'

export default defineComponent({
  components: {
    TmScrollbar,
  },
  props: {
    allowScrollY: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    let resizeObserver: ResizeObserver
    const reachIn = ref(false)
    const reachEnd = ref(false)
    const isScrollable = ref(true)
    const scrollbar = ref()

    const onResize = () => {
      const { offsetWidth, scrollWidth } = scrollbar.value.$el
      isScrollable.value = scrollWidth > offsetWidth
    }

    onMounted(() => {
      resizeObserver = new ResizeObserver(onResize)
      resizeObserver.observe(scrollbar.value.$el)
    })

    onBeforeUnmount(() => {
      resizeObserver.disconnect()
    })

    const handleReachIn = () => {
      reachIn.value = true
      reachEnd.value = false
    }
    const handleReachEnd = () => {
      reachIn.value = true
      reachEnd.value = true
    }
    const handleReachStart = () => {
      reachIn.value = false
      reachEnd.value = false
    }

    return {
      handleReachIn,
      handleReachEnd,
      handleReachStart,
      scrollbar,
      reachIn,
      reachEnd,
      isScrollable,
    }
  },
})
