import { computed, ref } from 'vue'
import type { WritableComputedRef } from 'vue'
import { rgbToHex, styleVars } from '@/compositions/styleVariables'

const hideDragImageId = 'hide-drag-image'
const dragImageId = 'drag-image'
const placeholderId = 'drag-element-placeholder'

let draggableElementOffsetX: number
let draggableElementOffsetY: number

type DraggableElementType = {
  id: string;
  column: string;
} | null

type UseKanbanDragAndDropType = {
  draggableElement: WritableComputedRef<DraggableElementType>;
  newElementIndex: WritableComputedRef<number | null>;
  handleDragStart: (event: DragEvent, element: HTMLElement) => void;
  handleDragOver: (event: DragEvent, element: HTMLElement, dragOverClass: string, horizontal: boolean) => number | undefined;
  handleDrag: (event: DragEvent) => void;
  handleDragLeave: (event: DragEvent, element: HTMLElement) => void;
  hideElement: (element: HTMLElement) => void;
  handleDragEnd: (element: HTMLElement) => void;
  showElement: (element: HTMLElement) => void;
  getPlaceholder: (height?: number) => HTMLElement;
}

const draggableElementValue = ref<DraggableElementType>(null)
const newElementIndexValue = ref<number | null>(null)

export const useKanbanDragAndDrop = (): UseKanbanDragAndDropType => {
  const draggableElement = computed({
    get: () => draggableElementValue.value,
    set: (value: DraggableElementType) => {
      draggableElementValue.value = value
    },
  })

  const newElementIndex = computed({
    get: () => newElementIndexValue.value,
    set: (value: number | null) => {
      newElementIndexValue.value = value
    },
  })

  const hideElement = (element: HTMLElement) => {
    element.style.visibility = 'hidden'
    element.style.position = 'absolute'
    element.style.left = '-9999px'
    element.style.top = '-9999px'
  }

  const showElement = (element: HTMLElement) => {
    element.style.visibility = 'visible'
    element.style.position = 'static'
  }

  const getPlaceholder = (height?: number, width?: number): HTMLElement => {
    if (document.querySelector(`#${placeholderId}`)) {
      return document.querySelector(`#${placeholderId}`)!
    }

    const kanbanPlaceholder: HTMLElement = document.createElement('div')

    kanbanPlaceholder.setAttribute('id', placeholderId)
    kanbanPlaceholder.style.height = `${height}px`
    kanbanPlaceholder.style.width = `${width}px`
    kanbanPlaceholder.style.minWidth = `${width}px`
    kanbanPlaceholder.style.background = rgbToHex(styleVars.gray3)
    kanbanPlaceholder.style.borderRadius = '8px'

    return kanbanPlaceholder
  }

  const handleDragEnd = (element: HTMLElement) => {
    const kanbanPlaceholder: HTMLElement = getPlaceholder()
    const hideDragImage = document.getElementById(hideDragImageId)
    const dragImage = document.getElementById(dragImageId)

    if (hideDragImage) { hideDragImage.remove() }
    if (dragImage) { dragImage.remove() }
    kanbanPlaceholder.remove()

    showElement(element)
  }

  const handleDragStart = (event: DragEvent, element: HTMLElement) => {
    element.style.width = `${element.offsetWidth}px`

    const hideDragImage = document.createElement('div') as HTMLElement
    hideDragImage.setAttribute('id', hideDragImageId)

    // add fake drag element
    const dragImage = element.cloneNode(true) as HTMLElement
    dragImage.id = dragImageId
    dragImage.style.position = 'absolute'
    dragImage.style.zIndex = '100'
    dragImage.style.pointerEvents = 'none'

    // replace real drag image
    hideDragImage.style.width = `${element.offsetWidth}px`
    hideDragImage.style.height = `${element.offsetHeight}px`
    hideDragImage.style.opacity = '0'
    document.body.appendChild(hideDragImage)
    document.body.appendChild(dragImage)
    event.dataTransfer!.setDragImage(hideDragImage, 0, 0)

    draggableElementOffsetX = event.clientX - element.getBoundingClientRect().x
    draggableElementOffsetY = event.clientY - element.getBoundingClientRect().y

    window.requestAnimationFrame(() => {
      const elementPlaceholder: HTMLElement = getPlaceholder(element.offsetHeight, element.offsetWidth)
      element.parentNode!.insertBefore(elementPlaceholder, element)
      // hide real drag element
      hideElement(element)
    })

    const body = document.querySelector('body')!
    const bodyDragLeaveEvent = (e: DragEvent) => {
      if (!e.clientX && !e.clientY) {
        handleDragEnd(element)
        body.removeEventListener('dragleave', bodyDragLeaveEvent)
      }
    }

    body.addEventListener('dragleave', bodyDragLeaveEvent)
  }

  const handleDragOver = (event: DragEvent, element: HTMLElement, dragOverClass: string, horizontal: boolean): number | undefined => {
    const targetElement: any = event.target
    if (targetElement.classList.contains(dragOverClass) || targetElement.closest(`.${dragOverClass}`)) {
      const element = targetElement.classList.contains(dragOverClass) ? targetElement : targetElement.closest(`.${dragOverClass}`)
      const kanbanPlaceholder: HTMLElement = getPlaceholder(element.offsetHeight, element.offsetWidth)

      const { y: elementY, x: elementX } = element.getBoundingClientRect()
      const elementReachCenter = horizontal ? element.offsetWidth / 2 + elementX > event.clientX : element.offsetHeight / 2 + elementY > event.clientY

      if (elementReachCenter) {
        if (element.previousElementSibling !== kanbanPlaceholder) {
          element.before(kanbanPlaceholder)
          return +element.getAttribute('index') - 1
        }
      } else {
        if (element.nextElementSibling !== kanbanPlaceholder) {
          element.after(kanbanPlaceholder)
          return +element.getAttribute('index') + 1
        }
      }
    }
  }

  const handleDrag = (event: DragEvent) => {
    const dragImage = document.getElementById(dragImageId)

    if (dragImage) {
      dragImage.style.left = `${event.clientX - draggableElementOffsetX}px`
      dragImage.style.top = `${event.clientY - draggableElementOffsetY}px`
    }
  }

  const handleDragLeave = (event: DragEvent, element: HTMLElement) => {
    const dragPlaceholder: HTMLElement = getPlaceholder()
    const rect = element.getBoundingClientRect()

    // Remove drag placeholder if element gets out of drag area
    if (dragPlaceholder && (event.clientY < rect.top || event.clientY >= rect.bottom || event.clientX < rect.left || event.clientX >= rect.right)) {
      dragPlaceholder.remove()
    }
  }

  return {
    handleDragEnd,
    handleDragLeave,
    handleDrag,
    handleDragOver,
    handleDragStart,
    showElement,
    hideElement,
    getPlaceholder,
    draggableElement,
    newElementIndex,
  }
}
