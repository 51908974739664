import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, mergeProps as _mergeProps, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5a5eb11c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "kanban-column__header" }
const _hoisted_2 = { class: "subhead-semi-bold-16" }
const _hoisted_3 = {
  key: 0,
  class: "ml-2 subhead-semi-bold-16 light--text"
}
const _hoisted_4 = { class: "d-flex" }
const _hoisted_5 = ["onDragstart"]
const _hoisted_6 = { class: "kanban-column__collapsed-title" }
const _hoisted_7 = { class: "subhead-semi-bold-16" }
const _hoisted_8 = { class: "ml-2 subhead-semi-bold-16 light--text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_button = _resolveComponent("tm-button")!
  const _component_tm_dropdown = _resolveComponent("tm-dropdown")!
  const _component_tm_scrollbar = _resolveComponent("tm-scrollbar")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["kanban-column", {
      'kanban-column--collapsed': _ctx.collapsed,
    }])
  }, [
    _createElementVNode("div", {
      class: "kanban-column__border",
      style: _normalizeStyle({
        'border-top-color': _ctx.color,
      })
    }, null, 4),
    _renderSlot(_ctx.$slots, "default", {}, () => [
      (!_ctx.collapsed)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", null, [
                _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.title), 1),
                (!_ctx.hideCounter)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.items.length), 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_tm_dropdown, { class: "ml-2" }, {
                  menu: _withCtx(() => [
                    _renderSlot(_ctx.$slots, "actions", {}, undefined, true)
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_tm_button, {
                      flat: "",
                      "icon-only": "",
                      size: "medium",
                      color: "transparent"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_tm_icon, {
                          name: "more_vert",
                          class: "pointer neutral--text",
                          size: "xLarge"
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 3
                })
              ])
            ]),
            _renderSlot(_ctx.$slots, "after-header", {}, undefined, true),
            _createElementVNode("div", {
              ref: "dragArea",
              class: "kanban-column__body",
              onDragleave: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.dragleave && _ctx.dragleave(...args))),
              onDragover: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.dragover && _ctx.dragover(...args)))
            }, [
              _createVNode(_component_tm_scrollbar, {
                class: "kanban-column__scroll",
                visible: ""
              }, {
                default: _withCtx(() => [
                  (_ctx.sortedItems.length)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.sortedItems, (item) => {
                        return (_openBlock(), _createElementBlock("div", _mergeProps({
                          key: item.id
                        }, item, {
                          class: _ctx.kanbanCardDraggableClass,
                          draggable: true,
                          onDragstart: ($event: any) => (_ctx.dragStart($event, item.id)),
                          onDragend: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.dragEnd && _ctx.dragEnd(...args)))
                        }), [
                          _renderSlot(_ctx.$slots, "card", { item: item }, undefined, true)
                        ], 16, _hoisted_5))
                      }), 128))
                    : _createCommentVNode("", true)
                ]),
                _: 3
              })
            ], 544)
          ], 64))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createVNode(_component_tm_button, {
              size: "small",
              "icon-only": "",
              flat: "",
              class: "mb-4",
              color: "transparent",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('collapse')))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_tm_icon, {
                  name: "tmi-open-fullscreen",
                  size: "large"
                })
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.title), 1),
              _renderSlot(_ctx.$slots, "after-title", {}, () => [
                _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.items.length), 1)
              ], true)
            ]),
            _renderSlot(_ctx.$slots, "collapsed", {}, undefined, true)
          ], 64))
    ], true)
  ], 2))
}