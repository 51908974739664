
import { defineComponent, computed, ref, onMounted, onBeforeUnmount } from 'vue'
import KanbanNewColumn from '@/components/pages/shared/kanban/KanbanNewColumn.vue'
import { useKanbanDragAndDrop } from '@/compositions/kanban/kanbanDragAndDrop'
import TmScrollbarXShadows from '@/components/shared/tmScrollbar/TmScrollbarXShadows.vue'
import type { PropType } from 'vue'
import type { KanbanColumnType, KanbanItemType } from '@/definitions/shared/kanban/types'
import { kanbanColumnDraggableClass } from '@/definitions/shared/kanban/data'

export default defineComponent({
  components: {
    TmScrollbarXShadows,
    KanbanNewColumn,
  },
  props: {
    hideNewColumn: {
      type: Boolean,
    },
    columns: {
      type: Array as PropType<KanbanColumnType[]>,
      required: true,
    },
    items: {
      type: Array as PropType<KanbanItemType[]>,
      required: true,
    },
  },
  emits: ['update:columns'],
  setup(props, { emit }) {
    const { handleDragStart, handleDrag, handleDragOver, handleDragEnd } = useKanbanDragAndDrop()

    const draggableElementNewIndex = ref<null | number>(null)
    const draggableElementId = ref<null | string>(null)
    const getDraggableElementHTML = (): HTMLElement | null => {
      if (!draggableElementId.value) { return null }

      return document.querySelector(`[id="${draggableElementId.value}"]`)!
    }

    const columnsComputed = computed(() => props.columns
      .map((e: KanbanColumnType) => ({ ...e, items: props.items.filter((task: KanbanItemType) => task.column === e.name) }))
      .sort((a: KanbanColumnType, b: KanbanColumnType) => a.index < b.index ? -1 : 1)
    )

    const updateColumn = (item: KanbanColumnType) => {
      emit('update:columns', props.columns.map((e: KanbanColumnType) => e.id === item.id ? item : e))
    }

    const drag = (e: DragEvent) => {
      handleDrag(e)
    }

    const dragStart = (e: DragEvent, id: string) => {
      const eventTarget: any = e.target

      if (eventTarget && eventTarget.getAttribute('id') !== id) {
        return
      }

      draggableElementId.value = id

      const draggableElementHTML: HTMLElement | null = getDraggableElementHTML()

      if (draggableElementHTML) {
        handleDragStart(e, draggableElementHTML)
      }
    }

    const dragEnd = (e: DragEvent) => {
      e.preventDefault()
      const draggableElementHTML: HTMLElement | null = getDraggableElementHTML()

      if (!draggableElementHTML || draggableElementId.value === null) { return }

      handleDragEnd(draggableElementHTML)

      if (typeof draggableElementNewIndex.value === 'number') {
        const newIndex: number = draggableElementNewIndex.value

        emit('update:columns', props.columns.map((e: KanbanColumnType) => ({
          ...e,
          index: e.id === draggableElementId.value ? newIndex : e.index < newIndex ? e.index - 1 : e.index + 1,
        })))
      }

      draggableElementId.value = null
      draggableElementNewIndex.value = null
    }

    const dragOver = (e: DragEvent) => {
      if (draggableElementId.value === null) { return }
      e.preventDefault()

      const draggableElementHTML: HTMLElement | null = getDraggableElementHTML()

      if (draggableElementHTML) {
        const newIndex = handleDragOver(e, draggableElementHTML, kanbanColumnDraggableClass, true)

        if (newIndex) {
          draggableElementNewIndex.value = newIndex
        }
      }
    }

    onMounted(() => {
      window.addEventListener('dragover', drag)
    })

    onBeforeUnmount(() => {
      window.removeEventListener('dragover', drag)
    })

    return {
      kanbanColumnDraggableClass,
      columnsComputed,
      updateColumn,
      dragOver,
      drag,
      dragEnd,
      dragStart,
    }
  },
})
