
import { defineComponent, ref } from 'vue'
import DealsColumn from '@/components/pages/deals/DealsColumn.vue'
import type { KanbanColumnType, KanbanItemType } from '@/definitions/shared/kanban/types'
import { dealsColumnsMock } from '@/definitions/deals/data'
import { getTableData } from '@/services/tableService'
import type { DealsCardType } from '@/definitions/deals/types'
import KanbanMain from '@/components/pages/shared/kanban/KanbanMain.vue'

export default defineComponent({
  components: {
    KanbanMain,
    DealsColumn,
  },
  setup() {
    const deals = ref(getTableData('deals').map((e: any, i: number) => ({ ...e, index: i })))
    const columns = ref(dealsColumnsMock)

    const updateColumn = (item: KanbanColumnType) => {
      columns.value = columns.value.map((e: KanbanColumnType) => e.id === item.id ? item : e)
    }

    const getTotalAmount = (deals: DealsCardType[]) => {
      return deals.reduce((sum: number, a: DealsCardType) => sum + a.value, 0)
    }

    const updateItemPosition = (value: KanbanItemType) => {
      deals.value = deals.value.map((e: KanbanItemType) => ({
        ...e,
        column: value.id === e.id ? value.column : e.column,
        index: e.id === value.id ? value.index : e.index < value.index ? e.index - 1 : e.index + 1,
      }))
    }

    return {
      updateItemPosition,
      updateColumn,
      getTotalAmount,
      deals,
      columns,
    }
  },
})
