
import { defineComponent } from 'vue'
import KanbanColumn from '@/components/pages/shared/kanban/KanbanColumn.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import { useDeals } from '@/compositions/deals/useDeals'
import DealsCard from '@/components/pages/deals/DealsCard.vue'

export default defineComponent({
  components: {
    DealsCard,
    KanbanColumn,
    TmDropdownItem,
  },
  props: {
    itemsLength: {
      type: Number,
    },
    totalAmount: {
      type: Number,
      default: 0,
    },
  },
  emits: ['collapse'],
  setup() {
    const { getFormattedValue } = useDeals()

    return {
      getFormattedValue,
    }
  },
})
