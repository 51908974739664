
import { defineComponent, ref, computed } from 'vue'
import type { PropType } from 'vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmScrollbar from '@/components/shared/TmScrollbar.vue'
import TmButton from '@/components/shared/TmButton.vue'
import { kanbanCardDraggableClass } from '@/definitions/shared/kanban/data'
import { useKanbanDragAndDrop } from '@/compositions/kanban/kanbanDragAndDrop'
import type { KanbanItemType } from '@/definitions/shared/kanban/types'

export default defineComponent({
  components: {
    TmButton,
    TmScrollbar,
    TmDropdown,
  },
  props: {
    hideCounter: {
      type: Boolean,
    },
    title: {
      type: String,
    },
    name: {
      type: String,
      required: true,
    },
    color: {
      type: String,
    },
    items: {
      type: Array as PropType<KanbanItemType[]>,
      default: () => ([]),
    },
    collapsed: {
      type: Boolean,
    },
  },
  emits: ['collapse', 'updateItemPosition'],
  setup(props, { emit }) {
    const { draggableElement, newElementIndex, handleDragStart, handleDragEnd, handleDrag, handleDragOver, handleDragLeave } = useKanbanDragAndDrop()

    const sortedItems = computed(() => props.items.sort((a: KanbanItemType, b: KanbanItemType) => a.index < b.index ? -1 : 1))
    const dragArea = ref<HTMLElement>()
    const showNewCardTop = ref(false)
    const showNewCardBottom = ref(false)

    const getDraggableElementHTML = (): HTMLElement | null => {
      if (!draggableElement.value) { return null }
      return document.querySelector(`[id="${draggableElement.value.id}"]`)!
    }

    const dragleave = (e: DragEvent) => {
      if (draggableElement.value === null) { return }

      handleDragLeave(e, dragArea.value!)
    }

    const dragover = (e: DragEvent) => {
      const draggableElementHTML: HTMLElement | null = getDraggableElementHTML()

      if (draggableElementHTML === null || !draggableElement.value) { return }

      e.preventDefault()

      draggableElement.value.column = props.name

      const newIndex = handleDragOver(e, draggableElementHTML, kanbanCardDraggableClass, false)

      if (typeof newIndex === 'number') {
        newElementIndex.value = newIndex
      }
    }

    const dragStart = (e: DragEvent, id: string) => {
      draggableElement.value = {
        id: id,
        column: props.name,
      }

      const draggableElementHTML: HTMLElement | null = getDraggableElementHTML()

      if (draggableElementHTML) {
        handleDragStart(e, draggableElementHTML)
      }
    }

    const drag = (e: DragEvent) => {
      handleDrag(e)
    }

    const dragEnd = (e: DragEvent) => {
      e.preventDefault()
      const draggableElementHTML: HTMLElement | null = getDraggableElementHTML()

      if (draggableElementHTML === null) { return }

      handleDragEnd(draggableElementHTML)

      if (typeof newElementIndex.value === 'number') {
        emit('updateItemPosition', { ...draggableElement.value, index: newElementIndex.value })
      }

      draggableElement.value = null
      newElementIndex.value = null
    }

    return {
      showNewCardTop,
      showNewCardBottom,
      sortedItems,
      kanbanCardDraggableClass,
      dragArea,
      drag,
      dragleave,
      dragover,
      dragEnd,
      dragStart,
    }
  },
})
